<template>
  <div style="position: relative;">
    <v-overlay :value="loading" absolute opacity="0">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
    ></v-progress-circular>
    </v-overlay>
    <v-row class="d-none d-md-flex justify-center">
      <v-col cols="12" md="1" xs="1" class="d-flex align-center justify-left" style="margin-right: auto;">
        <v-btn @click="previousGroup" :disabled="disableBack || loading" icon x-large><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-col>
      <v-col cols="12" md="2" v-for="(item, index) in value" :key="index">
        <v-card :disabled="loading" :color="selectedDay == item.day ? '#0085CA' : ''" @click="selectDay(item.day)">
          <v-card-text>
            <v-row class="text-center d-flex justify-center align-center">
              <v-col>
                <h3 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatMonth(item.day) }}</h3>
                <h3 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatDayOfMonth(item.day) }}</h3><br/>
                <h4 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatDay(item.day) }}</h4>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="1" xs="1" class="d-flex align-center justify-center" style="margin-left: auto;">
        <v-btn icon x-large @click="nextGroup" :disabled="disableForward || loading"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex d-md-none justify-center">
      <v-col cols="1" md="1" xs="1" class="d-flex align-center justify-center" style="margin-right: auto;">
        <v-btn @click="previousGroup" :disabled="disableBack || loading" icon x-large><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-col>
      <v-col cols="2" md="2" xs="1"  v-for="(item, index) in value" :key="index" style="padding: 0px !important; margin: 0px !important;">
        <v-card :disabled="loading" :color="selectedDay == item.day ? '#0085CA' : ''" @click="selectDay(item.day)" style="padding: 5px !important; margin: 5px !important;">
          <v-card-text style="padding: 0px !important; margin: 0px !important;">
            <v-row class="text-center d-flex justify-center align-center" style="padding: 0px !important; margin: 0px !important;">
              <v-col style="padding: 0px !important; margin: 0px !important;">
                <h4 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatMonth(item.day) }}</h4>
                <h4 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatDayOfMonth(item.day) }}</h4><br/>
                <h5 :class="selectedDay == item.day ? 'active-day' : ''">{{ formatDay(item.day) }}</h5>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1" md="1" xs="1" class="d-flex align-center justify-center" style="margin-left: auto;">
        <v-btn icon x-large @click="nextGroup" :disabled="disableForward || loading"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    disableBack: {
      type: Boolean,
      default: true
    },
    disableForward: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  data() {
    return {
      days: [ ],
      selectedDay: null,
      minimumDate: null
    }
  },
  watch: {
    value(newVal) {
      if(newVal && Array.isArray(newVal) && newVal.length > 0) {
        this.selectDay(newVal[0].day);
      }
    }
  },
  methods: {
    formatMonth(date) {
      return moment(date).format('MMM')
    },
    formatDay(date) {
      return moment(date).format('ddd')
    },
    formatDayOfMonth(date) {
      return moment(date).format('D')
    },
    selectDay: function(date) {
      this.selectedDay = date
      this.$emit('daySelected', date)
    },
    previousGroup: function () {
      this.$emit('backButton')
    },
    nextGroup: function () {
     this.$emit('forwardButton')
    }
  },
  mounted() {
    // If we don't have a start date then set it to the current day
    if(this.value && Array.isArray(this.value) && this.value.length > 0) {
     this.selectDay(this.value[0].day);
    }
  }
}
</script>

<style scoped>
  .active-day {
    color: #fff !important;
  }

  h2, h4 {
    white-space: nowrap !important;
  }
</style>
